import { Box, Stack, Typography } from "@mui/material";
import logo from './images/logo.svg'
import heightMap from './images/heightMap.svg'

function App() {
  return (
    <>
        <Box width={'100vw'} position={'fixed'} top={0}>
            <Stack m={'20px auto'} width={'700px'} maxWidth={'90vw'} direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
                <Typography variant="h5">Welsh 3000s</Typography>
                <Stack direction={'row'}>
                    <Typography variant="h3">Peak</Typography>
                    <img style={{width:'60px', height:'60px', margin:'0 30px'}} src={logo}/>
                    <Typography variant="h3">Moments</Typography>
                </Stack>
                <Typography variant="h5">Donate</Typography>
            </Stack>
        </Box>


        <img style={{position:'fixed', left:'5vw', bottom:'10vh', width:"90vw"}} src={heightMap}/>
    </>
  );
}

export default App;
